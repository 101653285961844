export const columns = [
{
    title: '菜单ID',
    dataIndex: 'id',
    key: 'id',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单名称',
    dataIndex: 'menuName',
    key: 'menuName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单标识',
    dataIndex: 'menuKey',
    key: 'menuKey',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单布局',
    dataIndex: 'component',
    key: 'component',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '父菜单ID',
    dataIndex: 'parentId',
    key: 'parentId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '打开方式',
    dataIndex: 'target',
    key: 'target',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '显示顺序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单类型（M目录 C菜单 F按钮）',
    dataIndex: 'menuType',
    key: 'menuType',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否显示',
    dataIndex: 'visible',
    key: 'visible',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '权限标识',
    dataIndex: 'perms',
    key: 'perms',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单图标',
    dataIndex: 'icon',
    key: 'icon',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '链接',
    dataIndex: 'path',
    key: 'path',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '重定向',
    dataIndex: 'redirect',
    key: 'redirect',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '强制菜单显示为Item而不是SubItem',
    dataIndex: 'hiddenChildren',
    key: 'hiddenChildren',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '特殊隐藏 PageHeader 组件中的页面带的 面包屑和页面标题栏',
    dataIndex: 'hiddenHeader',
    key: 'hiddenHeader',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人id',
    dataIndex: 'createUserId',
    key: 'createUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人',
    dataIndex: 'modifyUser',
    key: 'modifyUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人id',
    dataIndex: 'modifyUserId',
    key: 'modifyUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否删除',
    dataIndex: 'flagDel',
    key: 'flagDel',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '版本号',
    dataIndex: 'version',
    key: 'version',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
